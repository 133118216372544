<mat-card [class.configured]="configureSelected" [ngClass]="{large: largeChart() === 'large'}">
    <mat-card-header #header class="widget-header large">
        <mat-card-title class="title"> Chart Element </mat-card-title>

        <div class="actions">
            <button mat-stroked-button (click)="configure()">Configure <fa-icon inline icon="gear"></fa-icon></button>
        </div>
    </mat-card-header>

    <mat-card-content>
        <eaglei-nom-chart
            [config]="chartConfigOptions"
            [states]="filters().locations"
            [startDate]="filters().startDate"
            [endDate]="filters().endDate">
        </eaglei-nom-chart>
    </mat-card-content>
</mat-card>
