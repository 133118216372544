import {Component, computed, inject, OnDestroy, OnInit, Signal, ViewChild} from '@angular/core';
import {SystemEventService} from '../../../system-event/services/system-event.service';
import {GroupedSystemEvents} from '../../../system-event/classes/grouped-system-events';
import {FormControl} from '@angular/forms';
import {ReportService} from '../../services/report.service';
import {Report} from '../../classes/report';
import {State} from '../../../outage/classes/state';
import moment from 'moment';
import {MatDrawer} from '@angular/material/sidenav';
import {SitRepService} from '../../services/sit-rep.service';
import {SystemEvent} from '../../../system-event/classes/system-event';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ElementOptionsConfig} from '../../classes/element-option';
import {CardFilters} from 'frontend/src/shared/classes/card-filters';

@Component({
    selector: 'eaglei-sit-rep-data-report',
    templateUrl: './sit-rep-data-report.component.html',
    styleUrls: ['../reports.scss', './sit-rep-data-report.component.scss'],
})
export class SitRepDataReportComponent extends Report implements OnInit, OnDestroy {
    @ViewChild(MatDrawer) configPanel: MatDrawer;

    // Services
    private systemEventService = inject(SystemEventService);
    private reportService = inject(ReportService);
    private sitRepService = inject(SitRepService);

    // Filters
    public events: GroupedSystemEvents = {active: [], inactive: []};
    public eventControl: FormControl = new FormControl('');
    public endDate: moment.Moment = moment();
    public startDate: moment.Moment = moment().subtract(10, 'days');
    public minDateRange: moment.Moment = moment().subtract(30, 'days');
    public maxDateRange: moment.Moment = moment();

    // UI
    public openConfigSideBar: Signal<boolean> = computed(() => {
        const currentOptions = this.sitRepService.openedConfigOptions;

        return currentOptions()?.options?.length > 0;
    });

    public activeConfigOptions: Signal<ElementOptionsConfig> = this.sitRepService.openedConfigOptions;

    public filters = this.sitRepService.sitRepFilters;

    constructor() {
        super();

        this.reportService
            .getReportData()
            .pipe(takeUntilDestroyed())
            .subscribe((r) => this.initializeReportInfo(r));

        this.systemEventService
            .getGroupedEvents()
            .pipe(takeUntilDestroyed())
            .subscribe((val) => {
                this.events = this.systemEventService.groupedEvents$.value;
            });
    }

    public ngOnInit(): void {
        this.sitRepService.updateFilters({
            startDate: moment(this.startDate),
            endDate: moment(this.endDate),
        });
    }

    public updateEvent(event: SystemEvent) {
        this.startDate = event.eventStart;
        this.minDateRange = event.eventStart;

        this.endDate = event.eventEnd;
        this.maxDateRange = event.eventEnd;

        this.sitRepService.updateFilters({
            event,
            locations: event.states,
            startDate: this.startDate,
            endDate: this.endDate,
        });
    }

    public updateLocations(states: State[]): void {
        this.sitRepService.updateFilters({locations: states});
    }

    public updateDateRange(date: CardFilters): void {
        this.sitRepService.updateFilters({
            startDate: moment(date.startDate),
            endDate: moment(date.endDate),
        });
    }

    public applyConfig(): void {
        this.sitRepService.closeConfigSideBar(true);
    }

    public cancelConfig(): void {
        this.sitRepService.closeConfigSideBar(false);
    }

    public exportElementsZIP(): void {
        // TODO: Add ZIP export
    }
}
