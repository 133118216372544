<mat-card [class.configured]="configureSelected">
    <mat-card-content>
        <div class="content">
            <!-- title of metric with info icon       -->
            <div class="metric-header">
                <div>
                    <span>{{ displayText() }}</span>
                    <fa-icon icon="info-circle" inline></fa-icon>
                </div>
                <button mat-stroked-button (click)="configure()">
                    Configure
                    <fa-icon inline icon="gear"></fa-icon>
                </button>
            </div>

            <!--      Value displayed-->
            <div class="metric-value">
                @if (metricData()) {
                <span>{{ metricData().count | number }}</span>
                } @else {
                <span>Not Available</span>
                } @if (!isMax() && metricData()) {
                <div class="trending-indicator trend-{{ metricData().trendDirection.valueOf() }}">
                    @if (metricData().trendDirection.valueOf() === 'increasing') {
                    <fa-icon icon="arrow-trend-up"></fa-icon>
                    <span>{{ metricData().difference | number }}</span>
                    } @else if (metricData().trendDirection.valueOf() === 'decreasing') {
                    <fa-icon icon="arrow-trend-down"></fa-icon>
                    <span>{{ metricData().difference | number }}</span>
                    } @else {
                    <fa-icon icon="arrow-right"></fa-icon>
                    }
                </div>
                }
            </div>

            @if (isMax() && metricData()) {
            <div class="measurement-date">
                <span>Observed at: {{ metricData().timestamp | momentDate }}</span>
            </div>
            }
        </div>
    </mat-card-content>
</mat-card>
