import {AfterViewInit, Component, OnInit} from '@angular/core';
import {BaseElement} from '../../classes/base-element';
import {SitRepFilters} from '../../classes/sit-rep-filters';
import {SitRepDataReportComponent} from '../sit-rep-data-report/sit-rep-data-report.component';
import {ElementOption, ElementOptionsConfig} from '../../classes/element-option';

@Component({
    selector: 'eaglei-sit-rep-test-element',
    templateUrl: './sit-rep-test-element.component.html',
    styleUrls: ['../sit-rep-element.scss', './sit-rep-test-element.component.scss'],
})
export class SitRepTestElementComponent extends BaseElement implements AfterViewInit {
    // TODO: REMOVE TEST ELEMENT LATER

    public randomConfigValue: number = 2;

    constructor() {
        super();

        const randomSelect: ElementOption = {
            type: 'select',
            label: 'Random Config Option',
            value: {label: '2', value: 2},
            selectOptions: [
                {label: '1', value: 1},
                {label: '2', value: 2},
                {label: '3', value: 3},
                {label: '4', value: 4},
                {label: '5', value: 5},
            ],
        };

        this.configureOptions = {
            header: 'Test Config',
            options: [randomSelect],
        };
    }

    ngAfterViewInit() {}

    /**
     * Opens the Config Panel with the options of the element to change.
     * Waits for a response on what options to use.
     */
    protected configure(): void {
        this.sitRepService.openConfigSideBar(this, this.configureOptions);
    }

    public updateConfigValues(options: ElementOptionsConfig): void {
        // Handle updating the values from the config
        this.randomConfigValue = options.options.find((o) => o.label === 'Random Config Option').value.value;
        this.configureOptions.options = [...options.options];
    }
}
