import {Component, ElementRef, inject, ViewChild} from '@angular/core';
import {Map as LeafletMap} from 'leaflet';
import moment from 'moment/moment';
import {GenOutageAggregationLevel} from '../../../../../../generated/serverModels/GenOutageAggregationLevel';
import {ApplicationConfig} from '../../../../classes/application-config';
import {HistoricalOutageData} from '../../../layer/classes/historical-outage-data';
import {LeafletMapLayer} from '../../../layer/classes/leaflet-map-layer';
import {LeafletNomFilter} from '../../../layer/filters/leaflet-nom-filter';
import {LayerService} from '../../../layer/services/layer.service';
import {LeafletNomSource} from '../../../layer/sources/leaflet-nom-source';
import {MapOptions} from '../../../map/classes/map-options';
import {BaseElement} from '../../classes/base-element';
import {SitRepFilters} from '../../classes/sit-rep-filters';
import {ElementOptionsConfig} from '../../classes/element-option';
import {toObservable} from '@angular/core/rxjs-interop';
import {FileDownload} from '../../../../classes/file-download';

@Component({
    selector: 'eaglei-sit-rep-map',
    templateUrl: './sit-rep-map.component.html',
    styleUrls: ['./sit-rep-map.component.scss'],
})
export class SitRepMapComponent extends BaseElement {
    private nomLayer: LeafletMapLayer;
    public layerService = inject(LayerService);

    public nomSource: LeafletNomSource;

    // HTML Element Properties
    @ViewChild('mapTarget') mapTarget: ElementRef<HTMLElement>;

    // filters
    private layerFilters: LeafletNomFilter = new LeafletNomFilter();

    // map
    public mapOptions: MapOptions;
    public mapRef: LeafletMap;

    // configurations
    public selectedOption: string = '';

    public configureOptions: ElementOptionsConfig = {
        header: 'Map Options',
        options: [
            {
                type: 'select',
                label: 'Aggregation Level',
                value: 'county',
                selectOptions: [
                    {label: 'States/Territories', value: 'state'},
                    {label: 'Counties', value: 'county'},
                ],
            },
        ],
    };

    public showMask: boolean = false;

    constructor() {
        super();
        this.mapOptions = new MapOptions().setZoom(3, 1).setCenter(37.2, -92);
        this.mapOptions.onlyManualZoom = true;
        this.mapOptions.show = {
            sidebar: false,
            refresh: false,
            export: false,
            coverage: false,
            coordinate: false,
            zoom: true,
            panControl: true,
        };

        this.layerFilters.aggregationLevel = GenOutageAggregationLevel.state;

        toObservable(this.sitRepService.sitRepFilters).subscribe((filters: SitRepFilters) => {
            this.handleSitRepFilterChange(filters);
        });
    }

    protected handleSitRepFilterChange(filters: SitRepFilters) {
        this.layerFilters.runStartTime = moment(filters.date);
        this.layerFilters.states = filters.locations;

        this.getLayerInfo();
    }

    destroyMap(): void {
        this.nomSource?.removeFromMap();
    }

    private getLayerInfo() {
        const handleLayer = (layer: LeafletMapLayer) => {
            if (!this.nomSource) {
                this.nomSource = new LeafletNomSource(layer);
                this.nomSource.mapRef = this.mapRef;
            }

            this.nomSource.updateFilter('location', this.locationFilter.bind(this));
            this.getOutageData();
        };

        if (this.nomLayer) {
            handleLayer(this.nomLayer);
        } else {
            this.layerService.getLayerByHandle().subscribe((layer) => handleLayer(layer));
        }
    }

    // Renders the Map after updating the map size
    public renderMap(): void {
        this.mapRef.invalidateSize();
    }

    // Fetches the event outage data and adds it to the map.
    private getOutageData(): void {
        const handleOutages = (outages: HistoricalOutageData[]) => {
            this.nomSource.processFeatures(outages);
            this.nomSource.addToMap();
            this.nomSource.changeOpacity(0.8);
            this.nomSource.fitToFeatures();
        };

        this.layerFilters.runStartTime = ApplicationConfig.roundMinute(this.layerFilters.runStartTime);

        this.layerService.getHistoricalOutageData(this.layerFilters).subscribe((outages) => {
            handleOutages(outages);
        });
    }

    private locationFilter(f: any): boolean {
        const outage: HistoricalOutageData = f.properties;
        if (this.layerFilters.aggregationLevel === GenOutageAggregationLevel.county) {
            return this.layerFilters.states.map((s) => s.abbreviation).includes(outage.stateName);
        } else {
            return this.layerFilters.states.map((s) => s.id).includes(outage.stateId);
        }
    }

    protected configure(): void {
        this.sitRepService.openConfigSideBar(this as unknown as BaseElement, this.configureOptions);
    }

    public updateConfigValues(options: ElementOptionsConfig): void {
        this.configureOptions = options;
        this.layerFilters.aggregationLevel = GenOutageAggregationLevel.forName(
            this.configureOptions.options.find((o) => o.label === 'Aggregation Level').value
        );

        this.handleSitRepFilterChange(this.sitRepService.sitRepFilters());
    }

    public exportAsZip(): any {
        return this.sitRepService.exportAsZip().subscribe({
            next: (blobUrl: string) => {
                FileDownload.downloadFile('situation-report-map.zip', blobUrl);
            },
            error: (error) => {
                console.error('Unable to export map', error);
            },
        });
    }
}
