import {Component, computed, inject, model, OnInit, signal} from '@angular/core';
import {BaseElement} from '../../../../classes/base-element';
import {MatCard, MatCardContent, MatCardHeader} from '@angular/material/card';
import {MatButton} from '@angular/material/button';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {MatIcon} from '@angular/material/icon';
import {DecimalPipe, NgIf} from '@angular/common';
import {SharedModule} from '../../../../../../../shared/shared.module';
import {SitRepFilters} from '../../../../classes/sit-rep-filters';
import {filter, map, take} from 'rxjs/operators';
import {GenMaxImpact} from '../../../../../../../../generated/serverModels/GenMaxImpact';
import {OutageService} from '../../../../../outage/services/outage.service';
import moment from 'moment';
import {GenOutageSummaryTrend} from '../../../../../../../../generated/serverModels/GenOutageSummaryTrend';
import {ElementOptionsConfig} from '../../../../classes/element-option';
import {ApplicationConfig} from '../../../../../../classes/application-config';
import {takeUntilDestroyed, toObservable} from '@angular/core/rxjs-interop';

enum TrendDirection {
    NO_CHANGE = 'no-change',
    INCREASING = 'increasing',
    DECREASING = 'decreasing',
}

interface MetricInfo {
    count: number;
    difference?: number;
    trendDirection?: TrendDirection;
    timestamp?: moment.Moment;
}

@Component({
    selector: 'eaglei-sit-rep-high-level-metric',
    templateUrl: './sit-rep-high-level-metric.component.html',
    styleUrl: './sit-rep-high-level-metric.component.scss',
})
export class SitRepHighLevelMetricComponent extends BaseElement implements OnInit {
    private outageService = inject(OutageService);

    protected type = model.required<string>();
    protected metricData = signal<MetricInfo>(undefined);

    protected displayText = computed(() => {
        switch (this.type()) {
            case 'utility':
                return 'Utilities Impacted';
            case 'max-utility':
                return 'Max Utilities Impacted';
            case 'customer':
                return 'Customers Impacted';
            case 'max-customer':
                return 'Max Customers Impacted';
        }
    });
    protected isMax = computed(() => {
        return this.type()?.startsWith('max-');
    });
    public configureOptions: ElementOptionsConfig;

    constructor() {
        super();

        toObservable(this.sitRepService.sitRepFilters)
            .pipe(
                filter((filters: SitRepFilters) => {
                    return !this.isMax() || filters.event !== undefined;
                }),
                takeUntilDestroyed()
            )
            .subscribe((filters: SitRepFilters) => {
                this.handleSitRepFilterChange(filters);
            });
    }

    ngOnInit() {
        this.configureOptions = {
            header: 'Metric',
            options: [
                {
                    type: 'select',
                    label: 'Metric',
                    value: this.type(),
                    selectOptions: [
                        {value: 'utility', label: 'Utilities Affected'},
                        {value: 'max-utility', label: 'Max Utilities Affected'},
                        {value: 'customer', label: 'Customers Out'},
                        {value: 'max-customer', label: 'Max Customers Out'},
                    ],
                },
            ],
        };
    }

    protected handleSitRepFilterChange(filters: SitRepFilters): void {
        if (this.isMax()) {
            this.getMaxImpactMetric(filters);
        } else {
            this.getOutageTrends(filters);
        }
    }

    private getOutageTrends(filters: SitRepFilters): void {
        const trendLevel = this.type() === 'customer' ? 'state' : 'utility';
        this.outageService
            .getOutageSummaryTrend(
                trendLevel,
                filters.date,
                ApplicationConfig.roundMinute(filters.date).subtract(15, 'minutes'),
                filters.locations
            )
            .pipe(
                take(1),
                map((v: any) => this.handleSummaryTrend(v))
            )
            .subscribe((metric) => this.metricData.set(metric));
    }

    private getMaxImpactMetric(filters: SitRepFilters): void {
        this.outageService
            .getImpactMetric(filters.event, filters.date, filters.locations)
            .pipe(
                take(1),
                map((v: any) => this.handleMaxCounts(v))
            )
            .subscribe((metricInfo) => this.metricData.set(metricInfo));
    }

    private handleSummaryTrend(trends: GenOutageSummaryTrend[]): MetricInfo {
        let startCount = 0;
        let endCount = 0;
        let trendDirection = TrendDirection.NO_CHANGE;

        trends.forEach((trend) => {
            let startToAdd = trend.startCount;
            let endToAdd = trend.endCount;

            // In the case of utility, we only want to get a count of the number of impacted utilities, not the sum of the outages
            if (this.type() === 'utility') {
                startToAdd = trend.startCount > 0 ? 1 : 0;
                endToAdd = trend.endCount > 0 ? 1 : 0;
            }

            startCount += startToAdd;
            endCount += endToAdd;
        });

        if (startCount > endCount) trendDirection = TrendDirection.DECREASING;
        else if (startCount < endCount) trendDirection = TrendDirection.INCREASING;

        return {
            count: endCount,
            difference: Math.abs(startCount - endCount),
            trendDirection,
        };
    }

    private handleMaxCounts(impact: GenMaxImpact): MetricInfo {
        let count = impact.maxOutages;
        let timestamp = impact.maxOutagesTime;

        if (this.type() === 'max-utility') {
            count = impact.maxUtilitiesAffected;
            timestamp = impact.maxUtilitiesAffectedTime;
        }

        return {
            count,
            timestamp,
        };
    }

    /**
     * Opens the Config Panel with the options of the element to change.
     * Waits for a response on what options to use.
     */
    protected configure(): void {
        this.sitRepService.openConfigSideBar(this as unknown as BaseElement, this.configureOptions);
    }

    public updateConfigValues(options: ElementOptionsConfig): void {
        // Handle updating the values from the config
        this.configureOptions = options;
        this.type.update(() => {
            return this.configureOptions.options.find((o) => o.label === 'Metric').value;
        });
    }
}
